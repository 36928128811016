export const LOG_COLUMNS = [
  {
    title: '手机号',
    key: 'mobile',
    minWidth: 130,
  },
  {
    title: '验证码',
    key: 'vcode',
    minWidth: 100,
  },
  {
    title: '起始时间',
    key: 'start_time',
    minWidth: 180,
  },
  {
    title: '结束时间',
    key: 'end_time',
    minWidth: 180,
  },
];
