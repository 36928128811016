<template>
  <div class="verification-code-log">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchLogs" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="LOG_COLUMNS"
      :data="logs"
      v-model="queryData"
      @onPageConfigChange="fetchLogs" />
  </div>
</template>

<script>
import { LOG_COLUMNS } from './constants';
import VerificationCodeLogApi from './verification-code-log.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'verification-code-log',
  data() {
    return {
      LOG_COLUMNS,
      queryConfig: [
        {
          fieldName: 'mobile',
          name: '手机号',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        mobile: '',
      },
      logs: [],
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      const {
        page,
        limit,
        mobile,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await VerificationCodeLogApi.getLogs(
        page,
        limit,
        mobile,
      );
      this.logs = results;
      this.$set(this.queryData, 'count', count);
    },
    async onExport() {
      const {
        page,
        limit,
        mobile,
      } = this.queryData;
      const key = await VerificationCodeLogApi.export({
        page,
        limit,
        mobile,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async onExportAll() {
      const key = await VerificationCodeLogApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style scoped>

</style>
