import axios from '@/plugins/axios';

class VerificationCodeLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    mobile = '',
  ) {
    return axios({
      url: '/api/sys/log/vcode/page',
      data: {
        page,
        limit,
        mobile,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询验证码信息',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/sys/log/vcode/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default VerificationCodeLogApi;
